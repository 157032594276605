import React, { useState } from "react"
import {algorand_mnemonic} from "../helper/algo-func"
import {selectorH3array} from "../recoil/H3"
import {useRecoilState, useSetRecoilState, useRecoilValue} from 'recoil'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import Box from '@mui/material/Box'
import { styled } from '@mui/material/styles'
import Paper from '@mui/material/Paper'
import ListAltIcon from '@mui/icons-material/ListAlt'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Button from '@mui/material/Button'
import { MIN_noH3Indices } from '../helper/constants'
import { isH3ArrayValid } from "../helper/h3-func"
import {stateSnackBar} from "../recoil/SnackBar"
import {stateOpenModal} from "../recoil/Modal"
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import {MIN_secret} from '../helper/constants'


const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  boxShadow: 12,
  p: 4,
}

export default function SecretInput() {
  const h3Array = useRecoilValue(selectorH3array)
  const setDataSnackbar = useSetRecoilState(stateSnackBar)
  const [openModal, setOpenModal] = useRecoilState(stateOpenModal)

  const [secretInput, setSecretInput] = useState("")
  const [mnemonic, setMnemonic] = useState("")

  // -------------------------------------------------------
  // handleCloseModal
  // -------------------------------------------------------
  function handleCloseModal() {
    setMnemonic("")
    setSecretInput("")
    setOpenModal(false)
  }

  // -------------------------------------------------------
  // generateMSG
  // -------------------------------------------------------

  function generateMSG(e) {

    e.preventDefault()

    if (secretInput.length < MIN_secret) {
      setDataSnackbar({
        open: true,
        type: "warning",
        msg: "You need to set a secret of minimum " + MIN_secret + " characters"
      })      
      return
    }

    if (h3Array.length < MIN_noH3Indices) {
      setDataSnackbar({
        open: true,
        type: "warning",
        msg: "You need at least " + MIN_noH3Indices + " places"
      })
    } else {
      if (isH3ArrayValid(h3Array)) {
        const msg_tmp = "{'h3': '" + h3Array.join(", ") + "', " + 
                      "'network': 'algorand', " +
                      "'secret': '" + secretInput + "'}"

        setMnemonic( algorand_mnemonic(msg_tmp, secretInput) )
      } else {
        setDataSnackbar({
          open: true,
          type: "warning",
          msg: "We found locations that are not H3 valid"
        })
      }
    }

  }

  // -------------------------------------------------------
  // JSX
  // -------------------------------------------------------

  return (
    <Modal
      open={openModal}
      onClose={() => handleCloseModal()}
      >    
    <Box sx={style}>
      <Grid container justify="flex-end">
      <TextField
        placeholder={secretInput}
        label="Secret*"
        multiline
        rows={2}
        fullWidth
        id="secret-input"
        onChange={e => {
          setSecretInput(e.target.value)
          setMnemonic("")
        }
        }
      />
      </Grid>
      {(mnemonic.length === 0) &&
      <Grid container justifyContent="flex-end">
      <Button 
        variant="contained"
        onClick={generateMSG}
        endIcon={<ListAltIcon />}
      >Generate Account</Button>
      </Grid>
      }
      {(mnemonic.length > 0) &&
      <Grid container justifyContent="flex-end">
      <Button 
        variant="contained"
        onClick={handleCloseModal}
        endIcon={<CancelPresentationIcon />}
      >Close</Button>
      </Grid>
      }      
      {mnemonic && 
      <Divider sx={{m: 2}}>
        <Chip label="Algorand Account Passphrase/Mnemonic" />
      </Divider>
      }
      <Box sx={{ width: '100%' }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        {mnemonic && mnemonic.split(" ").map((word,index) => {
            return (
              <Grid item xs={4} key={"GridItem#"+index}>
                <Item>{index+1}: {word}</Item>
              </Grid>
            )
            })}
        </Grid>
      </Box>
      {mnemonic &&
      <Typography  sx={{m: 2}} variant="body2" gutterBottom>NOTE: the order of the words matter!</Typography>
      }
    </Box>
    </Modal>
  )
}

