import algosdk from 'algosdk'

const crypto = require('crypto');

export function algorand_mnemonic(msg, secret) {

    const hashSecret = crypto.createHash('sha256').update(secret).digest();
    const hmacDigest = crypto.createHmac('sha256', hashSecret).update(msg).digest()

    return algosdk.mnemonicFromSeed(hmacDigest)

}