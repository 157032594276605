export default function Logo() {

  // -------------------------------------------------------
  // JSX
  // -------------------------------------------------------

  return (

<svg
   xmlns="http://www.w3.org/2000/svg"
   viewBox="180 60 841.9 595.3"
>
  <g
     id="g1735"
     transform="matrix(10.456026,0,0,10.456026,-88.538378,-1028.2621)"
    >
    <path
       id="path1721"
       d="m 48.044845,104.3085 a 22.5,22.5 0 0 0 -22.446174,22.49992 22.5,22.5 0 0 0 22.499919,22.49992 22.5,22.5 0 0 0 17.553449,-8.42482 h -4.05763 a 19.5,19.5 0 0 1 -13.495819,5.425 19.5,19.5 0 0 1 -19.500102,-19.5001 19.5,19.5 0 0 1 19.430339,-19.50011 19.5,19.5 0 0 1 0.06976,0 19.5,19.5 0 0 1 10.610722,3.13986 l 2.29134,-2.07222 a 22.5,22.5 0 0 0 -12.902062,-4.06745 22.5,22.5 0 0 0 -0.05374,0 z" />
    <g
       id="g1733"
       transform="matrix(0.34482757,0,0,0.34482757,18.038816,108.19233)"
    >
      <path
         id="path1723"
         d="m 62.756674,48.702086 c -0.722024,2.451788 -2.647125,4.376888 -5.098915,5.098913 l 8.331792,9.929627 16.990693,20.248893 0.109037,0.109037 9.954432,-9.954948 -0.108522,-0.109037 -20.248893,-16.990695 z" />
      <path
         id="path1725"
         d="m 45.107085,31.110937 c -2.792524,0 -5.040519,2.24799 -5.040519,5.04052 v 9.91877 c 0,2.79252 2.247995,5.04052 5.040519,5.04052 h 9.918774 c 2.792523,0 5.040519,-2.248 5.040519,-5.04052 v -9.91877 c 0,-2.79253 -2.247996,-5.04052 -5.040519,-5.04052 z m -0.04083,4.99969 h 9.999908 v 10.00042 h -9.999908 z" />
      <path
         id="path1727"
         d="m 83.107368,69.111176 c -2.792524,0 -5.041035,2.247996 -5.041035,5.040519 v 3.971851 l 9.219591,10.987442 h 5.740218 c 0.287589,0 0.567634,-0.02911 0.841809,-0.07493 l 4.19871,-11.536249 v -3.348114 c 0,-2.792523 -2.247996,-5.040519 -5.040519,-5.040519 z" />
      <path
         id="path1729"
         d="m 141.10714,-8.889063 c -2.79255,0 -5.04057,2.247998 -5.04057,5.040522 v 9.918774 c 0,2.792523 2.24802,5.040519 5.04057,5.040519 h 9.91886 c 2.79256,0 5.04057,-2.247996 5.04057,-5.040519 v -9.918774 c 0,-2.792524 -2.24801,-5.040522 -5.04057,-5.040522 z m -0.0408,5.000213 h 10 v 9.999905 h -10 z" />
      <path
         id="path1731"
         d="m 133.22007,8.0802768 c -2.55118,2.2391162 -4.98299,4.4189862 -7.29826,6.5665242 -5.94114,5.510734 -11.20151,10.870959 -16.10186,16.715774 -5.26724,5.573673 -10.17841,11.647059 -15.070916,18.847946 -4.88288,7.186721 -9.715592,15.451317 -14.8239,25.355557 l 16.282726,7.08949 c 3.596751,-10.088108 6.88311,-18.502718 10.20041,-25.88214 3.31511,-7.374554 6.69624,-13.795017 10.51254,-19.881474 4.18205,-5.818264 8.78247,-11.284839 14.17846,-17.029969 1.98488,-2.113309 4.07938,-4.262888 6.28902,-6.474024 -2.11577,-0.999709 -3.68741,-2.950318 -4.16822,-5.3076842 z" />
    </g>
  </g>
</svg>

  )}