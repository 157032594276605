import {stateH3, selectorH3array, stateListH3expand} from "../recoil/H3"
import {useRecoilState, useSetRecoilState, useRecoilValue} from 'recoil'
import Box from '@mui/material/Box'
import List from '@mui/material/List'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemText from '@mui/material/ListItemText'
import IconButton from '@mui/material/IconButton'
import DeleteIcon from '@mui/icons-material/Delete'
import {stateViewPort} from "../recoil/Map"
import { h3ToGeo } from "h3-js"
import { Divider } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import ExpandLessIcon from '@mui/icons-material/ExpandLess'
import {MIN_noH3Indices, GOTO_zoom} from '../helper/constants'
import {stateSnackBar} from "../recoil/SnackBar"
import {stateOpenModal} from "../recoil/Modal"
import {stateOpenInfoBox} from "../recoil/InfoBox"

export default function ListH3() {

    const [expand, setExpand] = useRecoilState(stateListH3expand)
    const [h3Indices, setH3Indices] = useRecoilState(stateH3)
    const h3Array = useRecoilValue(selectorH3array)
    const [viewPort, setViewPort] = useRecoilState(stateViewPort)
    const setDataSnackbar = useSetRecoilState(stateSnackBar)
    const setOpenModal = useSetRecoilState(stateOpenModal)
    const setOpenInfoBox = useSetRecoilState(stateOpenInfoBox)

    // -------------------------------------------------------
    // handleDelete
    // -------------------------------------------------------
    function handleDelete(h3Item) {
        if ( h3Indices.has(h3Item) ) {
            h3Indices.delete(h3Item)
            setH3Indices(new Set(h3Indices))
        }
    }

    // -------------------------------------------------------
    // handleGoTo
    // -------------------------------------------------------
    function handleGoTo(h3Item) {
        const [lat, lng] = h3ToGeo(h3Item)
        setViewPort( {...viewPort, zoom:GOTO_zoom, latitude: lat, longitude: lng} )
    }

    // -------------------------------------------------------
    // Handle handleGenerateAccount
    // -------------------------------------------------------    
    function handleGenerateAccount() {
        if (h3Array.length < MIN_noH3Indices) {
            setDataSnackbar({
                open: true,
                type: "warning",
                msg: "You need at least " + MIN_noH3Indices + " places"
            })        
        } else {
            setOpenModal(true)
        }
    }

    // -------------------------------------------------------
    // JSX
    // -------------------------------------------------------

    return (
        <Box sx={{ width: 250, bgcolor: 'background.paper' }}>
        <nav aria-label="secondary mailbox folders">
            <List>
            {h3Array.length === 0 &&
                        <ListItem disablePadding>
                        <ListItemButton onClick={() => setOpenInfoBox(true)}>
                            <ListItemText primary={"ZOOM IN FOR LOCATIONS"} secondary={"(click for info)"} />
                        </ListItemButton>                    
                        </ListItem>
            }
            {h3Array.length > 0 && expand===true &&
                        <ListItem 
                            disablePadding
                            secondaryAction={
                                <IconButton 
                                    edge="end" 
                                    aria-label="expandLess"
                                    onClick={() => setExpand(false)}
                                >
                                  <ExpandLessIcon />
                                </IconButton>
                              }                            
                        >
                        <ListItemButton onClick={() => handleGenerateAccount()}>
                            <ListItemText primary={"CLICK FOR ACCOUNT"} secondary={h3Array.length+" locations in order"} />
                        </ListItemButton>                    
                        </ListItem>
            }
            {h3Array.length > 0 && expand===false &&
                        <ListItem 
                            disablePadding
                            secondaryAction={
                                <IconButton 
                                    edge="end" 
                                    aria-label="expandMore"
                                    onClick={() => setExpand(true)}
                                >
                                  <ExpandMoreIcon />
                                </IconButton>
                              }                            
                        >
                        <ListItemButton onClick={() => handleGenerateAccount()}>
                            <ListItemText primary={"CLICK FOR ACCOUNT"} secondary={h3Array.length+" locations in order "} />
                        </ListItemButton>                    
                        </ListItem>                            
            }
            <Divider />                
            {expand && h3Array.map((h3Item, index) => {
            return (
                <ListItem 
                    disablePadding 
                    key={h3Item}
                    secondaryAction={
                        <IconButton 
                            edge="end" 
                            aria-label="delete"
                            onClick={() => handleDelete(h3Item)}
                        >
                          <DeleteIcon />
                        </IconButton>
                      }
                >
                <ListItemButton onClick={() => handleGoTo(h3Item)}>
                    <ListItemText primary={"Location "+(index+1)} secondary={h3Item} />
                </ListItemButton>
                </ListItem>
                )
            })} 
            </List>
        </nav>
      </Box>   

    )
}