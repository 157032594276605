import WebMercatorViewport from "viewport-mercator-project"
import { polyfill, pointDist, UNITS, h3IsValid, hexArea } from "h3-js"
import {VISIBLE_RES, H3_RES} from '../helper/constants' 

// -------------------------------------------------------
// isH3ArrayValid
// -------------------------------------------------------  
export function isH3ArrayValid(h3Array) {

  let valid = true

  h3Array.forEach(h3 => {
    valid = valid && h3IsValid(h3)
  });

  return valid
}

// -------------------------------------------------------
// bboxFromViewport
// https://stackoverflow.com/questions/56646664/how-can-i-get-the-h3-hexagons-on-a-react-map-gl-deck-gl-viewport
// -------------------------------------------------------  
export function bboxFromViewport(viewport) {
  const projection = new WebMercatorViewport(viewport)
  const { height, width } = viewport

  const [west, north] = projection.unproject([0, 0])
  const [east, south] = projection.unproject([width, height])

  return { north, south, east, west }
}

// -------------------------------------------------------
// getResolution
// ------------------------------------------------------- 
export function getResolution({ nw, ne, sw, se }) {

  // use area at see whether the resolution is greater or equal than the visile one
  const a = pointDist(nw, ne, UNITS.m) * pointDist(nw, sw, UNITS.m)

  let max_a = 0
  let min_a = 0

  for (let res = VISIBLE_RES; res < 15; res++) {

    max_a = hexArea(res, UNITS.m2)
    min_a = hexArea(res+1, UNITS.m2)

    // console.log("RES="+res)
    // console.log("a="+a)
    // console.log("max_a="+max_a)
    // console.log("min_a="+min_a)
    // console.log("---------------")

    if (a < max_a && a > min_a) {
      // console.log("res="+res)
      return res
    }    
  }

  return 0
}

// -------------------------------------------------------
// getH3IndicesForBB
// ------------------------------------------------------- 
export function getH3IndicesForBB(
  { north, south, east, west },
  resolution = H3_RES
) {
  const nw = [north, west]
  const ne = [north, east]
  const sw = [south, west]
  const se = [south, east]

  const view_res = getResolution({nw, ne, se, sw})

  let hexes = []
  if (view_res >= VISIBLE_RES) {
    hexes = polyfill([nw, ne, se, sw], resolution)
  }

  return hexes
}
