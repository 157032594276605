import ReactMapGL from "react-map-gl"
import DeckGL from "@deck.gl/react"
import { H3HexagonLayer } from "@deck.gl/geo-layers"
import { TextLayer } from "@deck.gl/layers"
import { object } from "prop-types"
import { bboxFromViewport, getH3IndicesForBB } from "../helper/h3-func"
import {stateSnackBar} from "../recoil/SnackBar"
import {MAPBOX_TOKEN, MAX_noH3Indices} from '../helper/constants'
import {stateViewPort, stateMapStyle} from '../recoil/Map'
import {useRecoilState, useRecoilValue, useSetRecoilState} from 'recoil'
import { stateH3, selectorLocH3 } from "../recoil/H3"
import {useDebounce} from "../helper/react-func"

// These lines are added to solve the mapbox transpiling issue
import mapboxgl from 'mapbox-gl';
// The following is required to stop "npm build" from transpiling mapbox code.
// notice the exclamation point in the import.
// @ts-ignore
// eslint-disable-next-line import/no-webpack-loader-syntax, import/no-unresolved
mapboxgl.workerClass = require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

export default function Map() {

  const mapStyle = useRecoilValue(stateMapStyle)

  const [selectedH3Indices, onHexClick] = useRecoilState(stateH3)
  const selectedH3Locations = useRecoilValue(selectorLocH3)

  const [viewPort, setViewPort] = useRecoilState(stateViewPort)
  const setDataSnackbar = useSetRecoilState(stateSnackBar)

  const debouncedViewState = useDebounce(viewPort, 500)
  const boundingBox = bboxFromViewport(debouncedViewState)
  const h3Indices = getH3IndicesForBB(boundingBox)

  // -------------------------------------------------------
  // DeckGL layers
  // -------------------------------------------------------

  const layers = [   
    new TextLayer({
      id: "text-layer",
      data: selectedH3Locations,
      pickable: true,
      getPosition: (d) => d.coordinates,
      getText: (d) => d.index,
      onClick: (info) => {
        // zoom in to see the hexagon
        if (h3Indices.length === 0) {
          setViewPort( {...viewPort, zoom:22, latitude: info.object.coordinates[1], longitude: info.object.coordinates[0]})
        }
      },
      getSize: 32,
      getAngle: 0,
      getTextAnchor: 'middle',
      getAlignmentBaseline: 'center'
    }),    
    new H3HexagonLayer({
      id: "h3-hexagon-layer",
      data: h3Indices,
      pickable: true,
      wireframe: true,
      filled: true,
      extruded: true,
      elevationScale: 0,
      getHexagon: (d) => d,
      autoHighlight: true,
      getLineColor: [0, 0, 0],
      getFillColor: (d) => {
        const isSelected = selectedH3Indices.has(d)
        // rgba - rgb=0, but a=1 to make the hex clickable
        return isSelected ? [242, 141, 59, 50] : [0, 0, 0, 1]
      },
      opacity: 1,
      // onHover: (info) => console.log("hover", info),
      onClick: (info) => {
        const isAlreadySelected = selectedH3Indices.has(info.object)

        if (isAlreadySelected) {
          selectedH3Indices.delete(info.object)
        } else {
          if (selectedH3Indices.size >= MAX_noH3Indices) {
            setDataSnackbar({
              open: true,
              type: "info",
              msg: "You cannot select more than " + MAX_noH3Indices + " places"
            })
          } else {
            selectedH3Indices.add(info.object)
          }
        }

        // Set is a mutable data structure so modifying won't trigger a state update
        // so you have to create a new one - https://stackoverflow.com/questions/58806883/how-to-use-set-with-reacts-usestate
        onHexClick(new Set(selectedH3Indices))
      }
    }),     
    // // 
    // new TileLayer({
    //   id: 'TileLayer',
    //   data: 'https://c.tile.openstreetmap.org/{z}/{x}/{y}.png',
      
    //   /* props from TileLayer class */
      
    //   // extent: null,
    //   // getTileData: null,
    //   // maxCacheByteSize: null,
    //   // maxCacheSize: null,
    //   // maxRequests: 6,
    //   // maxZoom: 19,
    //   // minZoom: 0,
    //   // onTileError: null,
    //   // onTileLoad: null,
    //   // onTileUnload: null,
    //   // onViewportLoad: null,
    //   // refinementStrategy: 'best-available',
    //   renderSubLayers: props => {
    //     const {
    //       bbox: {west, south, east, north}
    //     } = props.tile;
    
    //     return new BitmapLayer(props, {
    //       data: null,
    //       image: props.data,
    //       bounds: [west, south, east, north]
    //     });
    //   },
    //   // tileSize: 512,
    //   // zRange: null,
    //   // zoomOffset: 0,
      
    //   /* props inherited from Layer class */
      
    //   // autoHighlight: false,
    //   // coordinateOrigin: [0, 0, 0],
    //   // coordinateSystem: COORDINATE_SYSTEM.LNGLAT,
    //   // highlightColor: [0, 0, 128, 128],
    //   // modelMatrix: null,
    //   // opacity: 1,
    //   pickable: true,
    //   // visible: true,
    //   // wrapLongitude: false,
    // })    
  ]

  // -------------------------------------------------------
  // JSX
  // -------------------------------------------------------

  return (
    <div style={{ height: "100%", width: "100%" }}>
      <DeckGL
        style={{ position: "relative" }}
        height={"100%"}
        width={"100%"}
        initialViewState={viewPort}
        onViewStateChange={({ viewState }) => setViewPort(viewState)}
        controller={true}
        layers={layers}
        // tooltip={"Location: {count}"}
        // getTooltip={(info) => selectedH3Indices.has(info.object) && "Location "+([...selectedH3Indices].indexOf(info.object)+1)}
        // onClick={() => console.log(viewPort)}
      >
        <ReactMapGL 
          mapboxApiAccessToken={MAPBOX_TOKEN}
          mapStyle={mapStyle}
          maxZoom={24}
        >
        </ReactMapGL>
      </DeckGL> 
    </div>
  )
}

Map.propTypes = {
  selectedH3Indices: object
}

