import Map from "./components/Map"
import ListH3 from "./components/ListH3"
import SecretInput from "./components/SecretInput"
import BasicSnackbar from "./components/BasicSnackbar"
import {stateSnackBar} from "./recoil/SnackBar"
import {useSetRecoilState, useRecoilState} from 'recoil'
import { GOTO_zoom, INIT_LOC, MAPBOX_TOKEN, mapStyleActions } from './helper/constants'
import { stateViewPort, stateMapStyle } from './recoil/Map'
import Geocoder from 'react-mapbox-gl-geocoder'
import GpsFixedIcon from '@mui/icons-material/GpsFixed'
import IconButton from '@mui/material/IconButton'
import SpeedDial from '@mui/material/SpeedDial'
import SpeedDialIcon from '@mui/material/SpeedDialIcon'
import SpeedDialAction from '@mui/material/SpeedDialAction'
import Div100vh from 'react-div-100vh'
import Logo from "./components/Logo"
import InfoBox from "./components/InfoBox"

export default function App() {

  const [viewPort, setViewPort] = useRecoilState(stateViewPort)  
  const setMapStyle = useSetRecoilState(stateMapStyle)
  const setDataSnackbar = useSetRecoilState(stateSnackBar)

  // -------------------------------------------------------
  // localization function
  // -------------------------------------------------------  

  function getLocation() {
    // if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(setLocation, errorLocation)
    // } else { 
      // setDataSnackbar("Geolocation is not supported by this browser.")
    // }
  }

  function errorLocation(error) {
    setDataSnackbar(error.code + " : " + error.message)
  }
  
  function setLocation(position) {
    // console.log("Latitude: " + position.coords.latitude + ", Longitude: " + position.coords.longitude)
    setViewPort({...viewPort, zoom: GOTO_zoom, latitude: position.coords.latitude, longitude: position.coords.longitude})
  }

  // -------------------------------------------------------
  // JSX
  // -------------------------------------------------------  

  return (
    <Div100vh>
      <div style={{ position: "absolute", top: 20, left: 10, zIndex: 20, height:95, width: 95 }}>
      <Logo />
      </div>
      <SpeedDial
        ariaLabel="SpeedDial basic example"
        sx={{ position: 'absolute', bottom: 20, left: 20 }}
        icon={<SpeedDialIcon />}
      >
        {mapStyleActions.map((action) => (
          <SpeedDialAction
            key={action.name}
            icon={action.icon}
            tooltipTitle={action.name}
            onClick={() => setMapStyle(action.mapbox)}
          />
        ))}
      </SpeedDial>      
      <div style={{ position: "absolute", top: 27, right: 30, zIndex: 20, background: "white" }}>
        <IconButton 
          onClick={() => getLocation()}
        ><GpsFixedIcon />
        </IconButton>
      </div>
      <div style={{ position: "absolute", top: 20, right: 20, zIndex: 10 }}>
        <Geocoder
          mapboxApiAccessToken={MAPBOX_TOKEN}                             
          viewport={viewPort}
          onSelected={(viewState) => setViewPort(viewState)}
          initialInputValue={INIT_LOC}
          updateInputOnSelect={true}
          hideOnSelect={true}
        />
      </div>      
      <div style={{ position: "absolute", bottom: 20, right: 20, zIndex: 1 }}>
        <ListH3></ListH3>
      </div>
      <Map />
      <SecretInput />
      <InfoBox />
      <BasicSnackbar></BasicSnackbar>
    </Div100vh>
  )
}
