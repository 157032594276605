import React from "react"
import {useRecoilState} from 'recoil'
import Box from '@mui/material/Box'
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';
import Button from '@mui/material/Button'
import {stateOpenInfoBox} from "../recoil/InfoBox"
import Divider from '@mui/material/Divider'
import Chip from '@mui/material/Chip'
import Modal from '@mui/material/Modal'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import {MIN_noH3Indices, MAX_noH3Indices, MIN_secret} from '../helper/constants'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '1px solid grey',
  boxShadow: 12,
  p: 4,
}

export default function InfoBox() {
  const [openInfoBox, setOpenInfoBox] = useRecoilState(stateOpenInfoBox)

  // -------------------------------------------------------
  // JSX
  // -------------------------------------------------------

  return (
    <Modal
      open={openInfoBox}
      onClose={() => setOpenInfoBox(false)}
      >    
    <Box sx={style}>
      <Typography  sx={{m: 2}} variant="body2" gutterBottom>
        Tired of forgetting the passphrase of your crypto wallet?
        Randcheck <b>geoWallet</b> hides your new wallet anywhere in the world.
      </Typography>      
      <Divider sx={{m: 2}}>
        <Chip label="How does it work?" />
      </Divider>      
      <Typography  sx={{m: 2}} variant="body2" gutterBottom>
        (1) Select between {MIN_noH3Indices} to {MAX_noH3Indices} hiding locations 
        anywhere in the world. <b>Zoom-in to see the selectable hexagons.</b> The order 
        of the locations matters - so please remember it.
      </Typography>      
      <Typography  sx={{m: 2}} variant="body2" gutterBottom>
        (2) <b>CLICK FOR ACCOUNT</b> and enter a <b>secret</b> of minimum {MIN_secret} characters.
      </Typography>      
      <Typography  sx={{m: 2}} variant="body2" gutterBottom>
        (3) <b>GENERATE ACCOUNT</b> and write down the passphrase to recover your
        account in the crypto wallet.
      </Typography>      
      <Divider sx={{m: 2}}>
        <Chip label="USE AT YOUR OWN RISK!" />
      </Divider>      
      <Typography  sx={{m: 2}} variant="body2" gutterBottom>
        DISCLAIMER: None of the authors, contributors, administrators, or anyone else connected 
        with Randcheck, in any way whatsoever, can be responsible for your use of 
        the information contained in or linked from these web pages. <b>This tool is for 
        entertainment only! Do not use in production!</b>
      </Typography>
      <Grid container justifyContent="flex-end">
      <Button 
        variant="contained"
        onClick={() => setOpenInfoBox(false)}
        endIcon={<CancelPresentationIcon />}
      >Acknowledge</Button>
      </Grid>
    </Box>
    </Modal>
  )
}

