import {atom, selector} from 'recoil';
import { h3ToGeo } from "h3-js"

export const stateH3 = atom({
    key: "h3",
    default: new Set()
})

export const selectorLocH3 = selector({
    key: 'selectorLocH3',
    get: ({get}) => {
        const selectedH3 = get(stateH3)
        const h3SelectedArray = Array.from(selectedH3)

        return h3SelectedArray.map((h3,index) => {
            const item = {}
            item["h3"] = h3
            item["index"] = ""+(index+1)
            item["coordinates"] = h3ToGeo(h3).reverse()
            return item
        })

    },
});

export const selectorH3array = selector({
    key: 'selectorH3array',
    get: ({get}) => {
        const selectedH3 = get(stateH3)
        return Array.from(selectedH3)
    },
});  

export const stateListH3expand = atom({
    key: "listH3expand",
    default: false
})