import {atom} from 'recoil';
import {mapStyleActions, INIT_LAT, INIT_LNG, MAP_HEIGHT, MAP_WIDTH} from '../helper/constants'

export const stateMapStyle = atom({
  key: "mapstyle",
  default: mapStyleActions[0].mapbox
})

export const stateViewPort = atom({
    key: "viewport",
    default: {
        latitude: INIT_LAT, 
        longitude: INIT_LNG,
        zoom: 14,
        maxZoom: 24,
        height: MAP_HEIGHT,
        width: MAP_WIDTH,
        padding: 30
    }
})