import MapIcon from '@mui/icons-material/Map'
// import AddRoadIcon from '@mui/icons-material/AddRoad'
import LandscapeIcon from '@mui/icons-material/Landscape'
import SatelliteIcon from '@mui/icons-material/Satellite'

// SecretInput
export const MIN_secret = 10    // # of min chars for the secret

// H3
export const VISIBLE_RES = 11
export const H3_RES = 14

// Map
export const MAP_HEIGHT = window.innerHeight
export const MAP_WIDTH = window.innerWidth
export const MIN_noH3Indices = 3
export const MAX_noH3Indices = 6

export const INIT_LOC = "Central Park, New York, New York, United States"
export const INIT_LAT = 40.78097473639522
export const INIT_LNG = -73.96621556481857

export const GOTO_zoom = 20

export const MAPBOX_TOKEN =
  "pk.eyJ1IjoicmFuZGNoZWNrIiwiYSI6ImNsNHJrbTJreTAwMGEzYnBndjBpMzg5OGUifQ.Z-WIkR2xzChLOTgZtuWA9w"

export const mapStyleActions = [
  { icon: <MapIcon />, name: 'Basic', mapbox: 'mapbox://styles/mapbox/basic-v9' },
  // { icon: <AddRoadIcon />, name: 'Streets', mapbox: 'mapbox://styles/mapbox/streets-v11' },
  { icon: <LandscapeIcon />, name: 'Outdoors', mapbox: 'mapbox://styles/mapbox/outdoors-v11' },
  // { icon: <SatelliteIcon />, name: 'Satellite', mapbox: 'mapbox://styles/mapbox/satellite-v9' },
  // { icon: <MapIcon />, name: 'Basic', mapbox: 'mapbox://styles/mapbox/basic-v7' },
  // { icon: <LandscapeIcon />, name: 'Outdoors', mapbox: 'mapbox://styles/mapbox/outdoors-v9' },
  { icon: <SatelliteIcon />, name: 'Satellite', mapbox: 'mapbox://styles/mapbox/satellite-streets-v11' },
]