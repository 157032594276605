import * as React from 'react'
import Snackbar from '@mui/material/Snackbar'
import Alert from '@mui/material/Alert'
import {stateSnackBar} from "../recoil/SnackBar"
import {useRecoilState} from 'recoil'

export default function BasicSnackbar() {

  const [dataSnackbar, setDataSnackbar] = useRecoilState(stateSnackBar)

  // -------------------------------------------------------
  // JSX
  // -------------------------------------------------------

  return (
    <Snackbar 
      open={dataSnackbar.open} 
      autoHideDuration={6000} 
      onClose={() => setDataSnackbar({...dataSnackbar, open:false})}
    >
      <Alert 
        onClose={() => setDataSnackbar({...dataSnackbar, open:false})} 
        severity={dataSnackbar.type}
        sx={{ width: '100%' }}
      >
      {dataSnackbar.msg}
      </Alert>
    </Snackbar>
  )

}

